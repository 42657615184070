import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const VideosPage = () => (
	<Layout>
		<SEO
			title="Video Packages"
			description="“In this online workshop we highlight the use of Agency in the NARM™ approach for resolving attachment and developmental trauma."
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						Video Packages
					</h5>
					<h1>NARM Video Packages</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="row justify-content-between align-items-center">
					<div class="col-md-6 mb-5">
						<LazyLoadImage
							effect="blur"
							src="/assets/img/videos/NARM-and-the-Use-of-Agency-2.png"
							class="img-fluid"
							alt="Narm and the use of Agency"
						/>
					</div>
					<div class="col-md-6 mb-5">
						<h4>Through June 30th, 2020 – Access for FREE!</h4>
						<p>
							This webinar uses NeuroAffective Relational Model (NARM) to
							present an introduction into a clinical orientation for addressing
							the historic and systemic oppression in American culture of
							minority groups, specifically focused on people of color and the
							LGBTQ community. Cultural trauma, a significant yet
							under-recognized aspect of Complex Trauma, can contribute to
							misattunement in therapeutic relationships due to lack of
							diversity in the mental health field, the legacy of systemic
							oppression in American institutions, implicit bias and other
							countertransference reactions within clinicians.
						</p>
						<a
							className="btn btn-primary inverted shadow arrow"
							href="https://narm.kartra.com/page/CulturalMisattunementWorkshopReplay"
							target="_blank"
						>
							Read More and Access for FREE
						</a>
					</div>
					<div class="col-md-6 mb-5">
						<LazyLoadImage
							effect="blur"
							src="/assets/img/videos/image1.png"
							class="img-fluid"
							alt="Narm and the use of Agency in the Resolution"
						/>
					</div>
					<div class="col-md-6 mb-5">
						<p>
							“In this online workshop we highlight the use of Agency in the
							NARM™ approach for resolving attachment and developmental trauma.
							While generally viewed as a synonym for responsibility or
							empowerment, we introduce a different perspective on agency and
							how we use it clinically in the NARM™ approach.
						</p>
						<p>Includes workshop video, audio file, transcript, and slides.</p>
						<a
							className="btn btn-primary inverted shadow arrow"
							href="https://narm.kartra.com/page/AgencyWorkshopReplay"
							target="_blank"
						>
							Read More and Purchase
						</a>
					</div>
					<div class="col-md-6 mb-5">
						<LazyLoadImage
							effect="blur"
							src="/assets/img/videos/image1.png"
							class="img-fluid"
							alt="Narm and the use of Agency in the Resolution"
						/>
					</div>
					<div class="col-md-6 mb-5">
						<p>A Four-Part Training Series</p>
						<p>
							Learn how NARM addresses complex issues related to developmental
							trauma including: working with clients who have difficulty
							accessing their body and emotions; the role of the therapist in
							working with Complex Trauma; how to navigate patterns of shame,
							guilt and self-hatred; and the spiritual dimensions of
							post-traumatic growth. Each one of the four programs includes:
						</p>
						<p>
							Overview & Discussion of a Core NARM Principle Demonstration Video
							Deconstructing a NARM Session Real-life Case Presentations Related
							to the Topic
						</p>
						<a
							className="btn btn-primary inverted shadow arrow"
							href="https://narm.kartra.com/page/rWc165"
							target="_blank"
						>
							Read More and Purchase
						</a>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default VideosPage
